import React, { createRef } from "react";
import styled from "styled-components";

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
`;

const DropDownContent = styled.div`
  display: box;
  margin-left: auto;
  position: fixed;
  z-index: 9999;
`;

class DropdownContainer extends React.Component {
  static defaultProps = {
    active: false,
    onActiveChange: () => {},
    dropdownContent: "",
    dropdownPosition: "bottom-left",
    dropSpace: 5
  };

  constructor(props) {
    super(props);

    this.componentRef = createRef();
    this.handleWindowClick = this.handleWindowClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleWindowClick);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleWindowClick);
  }

  handleWindowClick(e) {
    if (this.componentRef.current && this.props.active) {
      if (!this.componentRef.current.contains(e.target)) {
        this.props.onActiveChange(false);
      }
    }
  }

  get dropdownStyle() {
    const { dropdownPosition, dropSpace } = this.props;
    const component = this.componentRef.current;
    const zIndex = 9999;

    if (component) {
      const windowHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      );
      const windowWidth = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      );
      const componentHeight = Math.max(
        component.clientHeight,
        component.innerHeight || 0
      );
      const componentWidth = Math.max(
        component.clientWidth,
        component.innerWidth || 0
      );
      const componentTopOffset = component.getBoundingClientRect().top;
      const componentLeftOffset = component.getBoundingClientRect().left;

      const topSpace = componentTopOffset;
      const rightSpace = windowWidth - (componentWidth + componentLeftOffset);
      const bottomSpace = windowHeight - (componentHeight + componentTopOffset);
      const leftSpace = componentLeftOffset;

      if (dropdownPosition === "bottom-left") {
        return {
          position: "fixed",
          top: componentHeight + componentTopOffset + dropSpace + "px",
          right: rightSpace + "px",
          maxHeight: bottomSpace - bottomSpace + "px",
          zIndex: zIndex
        };
      }

      if (dropdownPosition === "bottom-right") {
        return {
          position: "fixed",
          top: componentHeight + componentTopOffset + dropSpace + "px",
          left: componentLeftOffset + "px",
          maxHeight: bottomSpace - bottomSpace + "px",
          zIndex: zIndex
        };
      }

      if (dropdownPosition === "top-left") {
        return {
          position: "fixed",
          bottom: windowHeight - topSpace + dropSpace + "px",
          right: rightSpace + "px",
          maxHeight: topSpace - dropSpace + "px",
          zIndex: zIndex
        };
      }

      if (dropdownPosition === "top-right") {
        return {
          position: "fixed",
          bottom: windowHeight - topSpace + dropSpace + "px",
          left: leftSpace + "px",
          maxHeight: topSpace - dropSpace + "px",
          zIndex: zIndex
        };
      }

      if (dropdownPosition === "left-bottom") {
        return {
          position: "fixed",
          top: topSpace + "px",
          right: windowWidth - leftSpace + dropSpace + "px",
          maxHeight: bottomSpace + componentHeight + "px",
          zIndex: zIndex
        };
      }

      if (dropdownPosition === "left-top") {
        return {
          position: "fixed",
          bottom: bottomSpace + "px",
          right: windowWidth - leftSpace + dropSpace + "px",
          maxHeight: topSpace + componentHeight + "px",
          zIndex: zIndex
        };
      }

      if (dropdownPosition === "right-bottom") {
        return {
          position: "fixed",
          top: topSpace + "px",
          left: leftSpace + componentWidth + dropSpace + "px",
          maxHeight: bottomSpace + componentHeight + "px",
          zIndex: zIndex
        };
      }

      if (dropdownPosition === "right-top") {
        return {
          position: "fixed",
          bottom: bottomSpace + "px",
          left: leftSpace + componentWidth + dropSpace + "px",
          maxHeight: topSpace + componentHeight + "px",
          zIndex: zIndex
        };
      }
    }

    return {
      marginTop: "5px",
      position: "absolute",
      top: "100%",
      right: 0,
      zIndex: "9999"
    };
  }

  render() {
    return (
      <div ref={this.componentRef} className="position-relative">
        <ButtonWrapper>{this.props.children}</ButtonWrapper>
        {this.props.active && (
          <DropDownContent
            style={{
              ...this.dropdownStyle
            }}
          >
            {this.props.dropdownContent}
          </DropDownContent>
        )}
      </div>
    );
  }
}

export default DropdownContainer;
