import { store } from "react-easy-state";
import { localStorageUtil } from "../utils/localStorageUtil";

interface Store {
  readonly logoSrc: string;
  readonly logoBlackSrc: string;
  readonly logoWhiteSrc: string;

  readonly iconLogoSrc: string;
  readonly iconLogoBlackSrc: string;
  readonly iconLogoWhiteSrc: string;

  readonly performanceLabel: string;
  readonly defaultFlow: string;

  readonly startView: string;
  readonly theme: string;
  meta: any;

  readonly enableLockSection: boolean;
  readonly enablePerformanceFilter: boolean;
  readonly enablePerformanceCsvExport: boolean;
  readonly enableAppV2: boolean;
}

export const appConfig = store<Store>({
  logoSrc: process.env.REACT_APP_DIANA_PLATFORM_LOGO,
  logoBlackSrc: process.env.REACT_APP_DIANA_PLATFORM_LOGO_BLACK,
  logoWhiteSrc: process.env.REACT_APP_DIANA_PLATFORM_LOGO_WHITE,

  iconLogoSrc: process.env.REACT_APP_DIANA_PLATFORM_ICON,
  iconLogoBlackSrc: process.env.REACT_APP_DIANA_PLATFORM_ICON_BLACK,
  iconLogoWhiteSrc: process.env.REACT_APP_DIANA_PLATFORM_ICON_WHITE,

  performanceLabel: process.env.REACT_APP_PERFORMANCE_LABEL as string,
  defaultFlow: process.env.REACT_APP_DEFAULT_FLOW as string,

  startView: process.env.REACT_APP_DIANA_PLATFORM_START_VIEW ?? "/app",
  theme:
    localStorageUtil.getItem("THEME") || process.env.REACT_APP_DEFAULT_THEME,
  meta: null,

  enableLockSection:
    process.env.REACT_APP_ENABLE_LOCK_SECTION === "true" ? true : false,
  enablePerformanceFilter:
    process.env.REACT_APP_ENABLE_PERFORMANCE_FILTER === "true" ? true : false,
  enablePerformanceCsvExport:
    process.env.REACT_APP_PERFORMANCE_CSV_EXPORT === "true" ? true : false,
  enableAppV2: process.env.REACT_APP_ENABLE_APP_V2 === "true" ? true : false,
});
