//import "bootstrap/dist/css/bootstrap.css";
import "./prestart";
import "./scss/custom.scss";
import "./scss/materialize/materialize.scss";
import "react-toastify/dist/ReactToastify.css";
import ReactDOM from "react-dom";
import { lazy, Suspense } from "react";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { Spinner } from "reactstrap";
import { CacheBusterContainer } from "./components/Containers";
import { localStorageUtil } from "./utils/localStorageUtil";
import { appConfig } from "./store/global";

const rootElement = document.getElementById("root");
const theme = localStorageUtil.getItem("THEME");
const history = createBrowserHistory();

const App = lazy(() => import("./App"));
const AppV2 = lazy(() => import("./AppV2"));

ReactDOM.render(
  <CacheBusterContainer>
    <Suspense
      fallback={
        <div className={`fallback-spinner ${theme}`}>
          <Spinner style={{ height: "3rem", width: "3rem" }} />
        </div>
      }
    >
      {appConfig.enableAppV2 === true ? (
        <AppV2 />
      ) : (
        <Router history={history}>
          <App />
        </Router>
      )}
    </Suspense>
  </CacheBusterContainer>,
  rootElement
);
