import React from "react";
import styled, { withTheme } from "styled-components";

const Container = styled.div`
  background: ${props => props.theme.body.background};
  color: ${props => props.theme.body.color};
  min-height: 100vh;
`;

const MainContainer = props => {
  return <Container {...props}>{props.children}</Container>;
};

export default withTheme(MainContainer);
