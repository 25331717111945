import moment from "moment";
import React from "react";
import { store, view } from "react-easy-state";
import { appConfig } from "../../../store/global";

global.appVersion = process.env.REACT_APP_VERSION || "100.0.0";

const currentVersion = global.appVersion;
const CACHE_NAME = "version-1";

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.substring(1).trim().split(/\./g);
  const versionsB = versionB.substring(1).trim().split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = versionsA.shift();
    const b = versionsB.shift();
    // when comparing a number 2 is less than 11
    // but 11 comes before 2 when sorted alphabetically
    // if they are numbers they must be converted first
    if (Number.isInteger(a) && Number.isInteger(b)) {
      const aNumber = Number(a);
      const bNumber = Number(b);

      if (aNumber === bNumber) continue;

      return aNumber > bNumber;
    } else {
      if (a === b) continue;

      return a > b;
    }
  }
  return false;
};

const cacheBuster = store({
  loading: false,
  isLatestVersion: false,
});
class CacheBusterContainer extends React.Component {
  refreshCacheAndReload(version) {
    try {
      //console.log("[Cache] Clearing cache and hard reloading...");
      if (caches) {
        //console.log("[Cache] Clearing cache and hard reloading...");
        // Service worker cache should be cleared with caches.delete()
        caches.delete(CACHE_NAME);
        // remove the service workers
      }

      if (navigator) {
        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            for (let registration of registrations) {
              if (registration.scope.startsWith(window.origin)) {
                // console.log("remove service worker " + registration.scope + ' ' + window.origin);
                registration.unregister();
              }
            }
          });
      }

      cacheBuster.loading = false;
      cacheBuster.isLatestVersion = true;
      // delete browser cache and hard reload
      // window.location.href = window.location.href;
      var refreshedVersion = localStorage.getItem("dianaAppVersion");
      if (refreshedVersion === undefined || refreshedVersion !== version) {
        localStorage.setItem("dianaAppVersion", version);
        window.location.reload(true);
      } else {
        console.log(`Refresh already called for version ${version}, skipping.`);
      }
    } catch (e) {
      console.log("Failed to reload window.", e);
    }
  }

  componentDidMount() {
    let { loading, isLatestVersion } = cacheBuster;

    console.log(
      `[Version] Loading:${loading} LatestVersion:${isLatestVersion}`
    );

    if (!loading && !isLatestVersion) {
      cacheBuster.loading = true;

      console.log(
        `[Version] Loading:${cacheBuster.loading} LatestVersion:${cacheBuster.isLatestVersion}`
      );

      fetch("/meta.json?id=" + moment().format("x"))
        .then((response) => response.json())
        .then((meta) => {
          const cdnVersion = meta.version;

          console.log(
            `[Version] Check Version: [CDN] v${cdnVersion} >= [BROWSER] v${currentVersion}? Update:${
              cdnVersion > currentVersion
            }`
          );

          appConfig.meta = meta;

          const shouldForceRefresh = semverGreaterThan(
            cdnVersion,
            currentVersion
          );
          if (shouldForceRefresh) {
            console.log(
              `[Version] A newer version detected [CDN] v${cdnVersion} > [BROWSER] v${currentVersion}. Forcing refresh`
            );

            cacheBuster.loading = false;
            cacheBuster.isLatestVersion = false;

            this.refreshCacheAndReload(cdnVersion);
          } else {
            console.log(
              `[Version] You already have the latest version - ${currentVersion} >= ${cdnVersion} No cache refresh needed.`
            );
            cacheBuster.isLatestVersion = true;
            cacheBuster.loading = false;
          }

          console.log(
            `[Version] Loading:${cacheBuster.loading} LatestVersion:${cacheBuster.isLatestVersion}`
          );
        })
        .finally(() => (cacheBuster.loading = false));
    }
  }
  render() {
    console.log(
      `[Render] Loading:${cacheBuster.loading} LatestVersion:${cacheBuster.isLatestVersion}`
    );

    if (cacheBuster.loading) {
      return <div className="fallback-spinner">Checking version...</div>;
    }

    if (!cacheBuster.loading && !cacheBuster.isLatestVersion) {
      return <div>New Version {} Available, Refreshing Cache...</div>;
    }

    return <>{this.props.children}</>;
  }
}

export default view(CacheBusterContainer);
