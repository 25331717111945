import React from "react";
import styled, { withTheme } from "styled-components";

const Container = styled.div`
  padding: 0 15px;
  
  @media (min-width: 768px) {
    padding: 0 30px;
  }
`;

const FluidContainer = props => {
  return <Container {...props}>{props.children}</Container>;
};

export default withTheme(FluidContainer);